.commonWidth {
    width: 1820px;
    margin: 0 auto;
}

.HomeMain_container .HomeBanner_container {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/Home_MainBanner.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.HomeMain_container .homeBanner_Main {
    padding: 52px 0;
}

.HomeMain_container .bannerTop {
    background: rgb(234, 249, 253);
    background: linear-gradient(180deg, rgba(234, 249, 253, 1) 0%, rgba(253, 255, 255, 1) 50%, rgba(234, 249, 253, 1) 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    box-shadow: -1px 1px 6px 0px rgb(0 0 0 / 18%);
}

.HomeMain_container .topRight_text {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/topBanner_bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 78%;
    border-radius: 5px;
    padding: 13px;
    border: 1px solid #ffffffad;
    padding-right: 19%;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.HomeMain_container .topRight_text h1 span {
    background: rgb(250, 251, 252);
    background: linear-gradient(180deg, rgba(250, 251, 252, 1) 0%, rgba(168, 188, 199, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.HomeMain_container .topRight_text h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    color: #ffe800;
    text-transform: uppercase;
    padding: 5px 4px;
}

.HomeMain_container .topRight_text h2 {
    font-size: 27px;
    font-weight: 500;
    color: #00e4ff;
    line-height: 35px;
}
.stuker_banner .topRight_text h2 {font-size: 27px;line-height: 31px;}
.stuker_banner .stuker_right_text {    padding: 16px !important;}
.georgedan_banner_top .george_title {
    padding-right: 21%;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/mike_banner_blue_bg.webp);
    background-position: right top;width: 57%;
}

.georgedan_banner_top .topRight_text h1 {
    font-size: 30px;
    line-height: 34px;
}

.georgedan_banner_top .topRight_text h2 {
    font-size: 26px;
    line-height: 30px;
}

.georgefooter_block .george_footer_text h1 {
    font-size: 30px !important;
    line-height: 32px !important;
    color: #ffe800 !important;
}

.casey_banner .stuker_left_part1 {
    background: #091a2a;
}

.casey_banner .stuker_left_part1 img {
    max-width: 90%;
}

.blackbg_logo {
    background: #091a2a;
    margin-bottom: 5px;
    border: 1px solid #2a929d;
}

.budchallan_banner .topRight_text h2 span {
    color: #ffe800 !important;
}

.caseyfooter_block .bottom_bannerPart2 .bottom_banner_jack_block1:nth-of-type(1) div img {
    max-width: 90%;
    margin: 0 auto;
    display: block;
    padding: 5px 0;
}

.caseyfooter_block .bottom_bannerPart2 .bottom_banner_jack_block1:nth-of-type(1) {
    width: 15%;
}

.HomeMain_container .topLogo {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.Zufelt_banner .topRight_text h1 {font-size: 36px;line-height: 38px;}
.Zufelt_banner .topRight_text h2 {font-size: 36px;line-height: 38px;}
.Zufelt_banner .topRight_text {width: 61%;padding-right: 18%;}
.caseyfooter_block .fbanner_leftText {
    background: linear-gradient(180deg, rgba(84, 201, 248, 1) 20%, rgba(195, 246, 254, 1) 56%, rgba(84, 201, 248, 1) 81%);
}

.caseyfooter_block .bottom_banner_jack_block1:nth-of-type(1) span {
    background: transparent;
}

.casey_banner .stuker_left_part1 img {
    max-width: 90%;
    margin: 0 auto;
}

.casey_banner .topRight_text h1 {
    font-size: 30px;
    line-height: 33px;
}
.casey_banner .topRight_text h1 {font-size: 30px;
    line-height: 33px;}
.budchallan_banner .topRight_text h2 {
    font-size: 25px;
    line-height: 30px;
    margin-top: 10px;
}

.mary_banner .stuker_right {
    width: 77.5%;
}

.casey_banner .stuker_left_part1 {
    width: 39%;
}

.casey_banner .stuker_left_part3 {
    width: 30%;
}

.casey_banner .stuker_left {
    width: 44%;
}

.casey_banner .stuker_right {
    width: 55.5%;
}

.mary_banner .stuker_left {
    width: 22%;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #adc0ca, #fff 50%, #adc0ca);
}

.mary_banner .stuker_left_part3 {
    width: 100%;
}

.mary_banner .topRight_text h1 {
    font-size: 35px;
    line-height: 38px;
}

.mary_banner .topRight_text h2 {
    font-size: 32px;
    line-height: 35px;
    margin-top: 10px;
}

.mary_banner .stuker_left img {
    width: 90%;
}

.HomeMain_container .topLogo img {
    max-width: 90%;
}

.happiness_banner .stuker_left_part1 {
    background: #fff;
}

.happiness_banner .stuker_left_part2 {
    background: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/sampson_banner_blue.webp);
    background-repeat: no-repeat;
    background-size: cover;
}

.happiness_banner .stuker_left_part2 span {
    color: #01e4fe;
    text-shadow: none;
}

.happiness_banner .stuker_left_part1 img {
    width: 100%;
    max-width: 85%;
}

.happiness_banner .stuker_right_text {
    border: none;
    /* height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
}
.happiness_banner .topRight_text h1 {font-size: 35px;line-height: 38px;}
.happiness_banner .topRight_text h2 {font-size: 29px;line-height: 32px;margin-top: 6px;}
.simpsonfooter_block .bottom_banner_jack_IMG {
    top: -40px;
}

.simpsonfooter_block .blackbg_logo {
    background: transparent;
    border: none;
}

.simpsonfooter_block .bottom_bannerPart2 .bottom_banner_jack_block1:nth-of-type(1) div img {
    display: block;
}

.mike_banner .stuker_right {
    background: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/mike_banner_blue_bg.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.mike_banner .mike_right_text {
    padding-right: 23% !important;
}

.mike_banner .topRight_text h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
}

.mike_banner .topRight_text h2 {
    font-size: 25px;
    line-height: 28px;
}

.mike_footer_block .bottom_banner_jack_block1:nth-of-type(2) h1 span {
    display: block;
}

.restaurant_banner .topRight_text {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/restaurants_banner_img.webp);
    padding-right: 20%;
    width: 58%;
}

.restaurant_banner .topRight_text h1 {
    font-size: 30px;
    line-height: 32px;
}

.restaurant_banner .topRight_text h2 {
    font-size: 26px;
    line-height: 28px;
}

.restaurant_footer_block .bottom_banner_jack_block1:nth-of-type(2) h1 span {
    display: block;
}

.restaurant_footer_block .bottom_banner_jack_IMG {
    top: 0 !important;
}

.generic_banner .stuker_right {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/stuker_top_banner_BG.webp);
    background-size: 100% 100%;
}

.generic_banner .topRight_text h1 {
    font-size: 34px;
    line-height: 38px;
}

.generic_banner .topRight_text h2 {
    font-size: 32px;
    line-height: 35px;
    margin-top: 10px;
}

.generic_footer .bottom_banner_jack_IMG {
    right: -17px;
}

.generic_banner .topRight_text h1 {
    font-size: 34px;
    line-height: 36px;
}

.generic_banner .topRight_text h2 {
    font-size: 32px;
    line-height: 36px;
}

.budchallan_banner .stuker_left_part1 {
    background: #00248f;
    width: 42%;
}

.budchallan_banner .stuker_right {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/bud_challan_banner_bg.webp);
    background-position: right top 0px;
}

.budchallan_banner .topRight_text {
    padding: 18px !important;
    padding-right: 23% !important;


}

.budchallan_banner .stuker_left_part2 {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/sky_gradient_bg.webp);
    background-size: cover;
    width: 25%;
}
.budchallan_banner .stuker_left_part3 {width: 30%;}
.budchallan_banner .topRight_text h2 span {background: linear-gradient(180deg, rgba(250, 251, 252, 1) 0%, rgba(168, 188, 199, 1) 100%);
  margin-top: 0;-webkit-text-fill-color: transparent;-webkit-background-clip: text;}
    .budchallan_banner .topRight_text h2 {color: #ffe800 !important;line-height: 33px;    font-size: 31px; font-weight: 600;text-transform: uppercase;
        }
.budchallan_footer .blackbg_logo img {
    width: 100%;
}

.budchallan_banner .stuker_left_part2 span {
    font-size: 28px;
    line-height: 32px;
}

.budchallan_footer .blackbg_logo {
    border: none;
    background: none;
}

.budchallan_footer .bottom_bannerPart2 .bottom_banner_jack_block1:nth-of-type(1) div img {
    max-width: 100%;
}

.richard_banner .stuker_left_part1 {
    background: #112e50;
}
.richard_banner .topRight_text h1 {font-size: 27px;line-height: 30px;}
.richard_banner .topRight_text h2 {font-size: 22px;line-height: 28px;}

.morhea_footer .bottom_banner_jack_IMG {
    top: -18px;
}

.morhea_footer .bottom_banner_jack_block1 h1 {
    font-size: 26px !important;
    line-height: 33px !important;
}

.morhea_footer .bottom_bannerPart2 .bottom_banner_jack_block1:nth-of-type(1) div img {
    max-width: 68%;
}

.morhea_footer .blackbg_logo {
    background: #07224b;
    border: 2px solid #0f498f;
}

.HomeMain_container .bannerMiddle {
    display: flex;
    justify-content: space-between;
    margin: 14px 0;
    /* align-items: center; */
}

.georgefooter_block .bottom_banner_jack_IMG {
    top: inherit;
    bottom: 0;
}
.peter_banner .stuker_left_part1 {    background: linear-gradient(180deg, rgb(173 192 202 / 79%) 0%, rgb(255 255 255 / 71%) 50%, rgb(173 192 202 / 91%) 100%);width: 36%;   }

.peter_banner .topRight_text h1 {font-size: 27px;font-weight: 700;line-height: 26px;}
.peter_banner .topRight_text h2 {font-size: 22px;line-height: 25px;margin-top: 10px;}
.peter_banner .stuker_left_part2 span {font-size: 28px;line-height: 34px;color: #112d50;}
.peter_banner .stuker_left_part2 {background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/asky_bg.webp);background-size: cover;background-repeat: no-repeat;width: 24%;}
.peter_banner .stuker_left_part3 {width: 38%;}
.peter_banner .topRight_text {padding: 10px !important;}
.peter_banner .stuker_left {width: 49%;}
.peter_banner .stuker_right {width: 50.5%;}
.peter_footer .bottom_bannerPart2 .bottom_banner_jack_block1:nth-of-type(1){width: 10%;}
.peter_footer .bottom_banner_jack {justify-content: space-between;}
.peter_footer .image_inner_wrp {padding: 15px;background: #7190ab8f;}
.peter_footer .image_inner_wrp img {display: block;}
.HomeMain_container .videoWrapper {
    width: 42.5%;
    display: flex;
    align-items: 100%;
    overflow: hidden;
    padding-top: 0;
    position: relative;
    border: 10px solid #d7f3fb;
    background-color: #000000;
    align-items: center;
    justify-content: center;
}

.HomeMain_container .videoWrapper_iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    background-color: #000;
}


/* Then style the iframe to fit in the container div with full height and width */

.HomeMain_container .videoWrapper_iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.HomeMain_container .videoWrapper_iframe img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.HomeMain_container .ReactPlayerBG {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.HomeMain_container .rightcontent_Wrapper {
    width: 55%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.HomeMain_container .asPireStep_Block {
    width: 46.8%;
    background-color: #ffffff8f;
    padding: 9px;
    position: relative;
    box-shadow: -1px 1px 6px 0px rgb(0 0 0 / 18%);
}

.HomeMain_container .asPireStep_Block .arrowdownnforscroll {
    content: "";
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/donwload_button.png);
    background-repeat: no-repeat;
    height: 65px;
    width: 65px;
    cursor: pointer;
}

.HomeMain_container .asPireStep_BlockWrp {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/AspireBG.webp);
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #fff;
    height: 100%;
}

.HomeMain_container .asPireLogo {
    /* background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/AspireLgobg.webp);
    background-repeat: no-repeat;
    background-size: cover; */
    background: rgb(254, 254, 254);
    background: linear-gradient(180deg, rgba(254, 254, 254, 1) 0%, rgba(208, 213, 215, 1) 100%);
    margin: 2px;
    padding: 18px 15px;
}

.HomeMain_container .asPireLogo img {
    display: block;
    width: 85%;
    object-fit: cover;
    margin: 0 auto;
}

.HomeMain_container .asPireText {
    padding: 6px 22px 22px;
    position: relative;
}

.HomeMain_container .asPireText .stePWrp {
    position: absolute;
    right: 12px;
    top: 0;
    font-size: 36px;
    color: #ffe800;
    font-weight: 600;
}

.HomeMain_container .asPireText h1 {
    background: rgb(250, 251, 252);
    background: linear-gradient(180deg, rgba(250, 251, 252, 1) 0%, rgba(168, 188, 199, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-size: 48px;
    text-transform: uppercase;
}

.HomeMain_container .asPireText h3 {
    background: rgb(250, 251, 252);
    background: linear-gradient(180deg, rgba(250, 251, 252, 1) 0%, rgba(168, 188, 199, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    font-size: 30px;
    text-transform: capitalize;
    border-bottom: 6px solid #00ddff;
    margin-top: -16px;
    padding-bottom: 3px;
}

.HomeMain_container .asPireText p {
    color: #e6e9ea;
    font-weight: 600;
    font-size: 23px;
    width: 87%;
    padding-top: 6px;
    line-height: 31px;
}

.HomeMain_container .bannerBottom {
    border: 9px solid rgba(255, 255, 255, 0.411);
}

.HomeMain_container .bannerBottomInner {
    background: rgb(0, 182, 250);
    background: linear-gradient(180deg, rgba(0, 182, 250, 1) 0%, rgba(1, 97, 150, 1) 100%);
}

.HomeMain_container .bannerBottomInner p {
    font-size: 40px;
    font-weight: 600;
    background: linear-gradient(180deg, rgb(255 255 255) 0%, rgb(195 193 193) 81%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    padding: 16px;
    filter: drop-shadow(1px 1px 3px #000);
}

.HomeMain_container .fooTerCopyright {
    background: #191818;
    margin-top: -5px;
}

.HomeMain_container .fooTerCopyright p {
    color: #737476;
    font-size: 16px;
    /* text-align: center; */
}

.HomeMain_container .footerSection {
    display: flex;
    padding: 20px 0;
    justify-content: space-between;
    align-items: center;
}

.HomeMain_container .footer_login_btn button {
    background-color: #aaf55bbf !important;
    font-weight: bold;
    border-radius: 3px !important;
}

.HomeMain_container .bottomAllSection_main {
    margin-top: -5px;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/aspire_form_Onlybg.webp);
    background-size: cover;
    background-repeat: no-repeat;
}

.HomeMain_container .home-middle-bck {
    /* margin-top: -5px; */
}


/* ====temporary css================== */

.HomeMain_container .formWrapperMain {
    background-color: #ffffff;
    width: 470px;
    /* margin: 0 auto; */
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0px 1px 4px 8px #0000001f;
}


/* .lastSection_logo img{
    width: 99%;
    display: block;
    margin: 0 auto;
} */

.HomeMain_container .unilityBlock_main img {
    margin: -73px 0 0 0;
}


/* .formWrapMain{
    background: beige;
} */


/* ====temporary css================== */

.HomeMain_container .formWrapperMain .formInline {
    background: #fff;
    /* padding: 10px; */
    /* width: 480px; */
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
}

.HomeMain_container .formWrapperMain .formInline .countDiv__column--col12 {
    flex: 1 0 100%;
    margin: 0 0 12px 0;
}

.HomeMain_container .formWrapperMain .formInline .countDiv__column--col12 .MuiFormControl-root {
    width: 100%;
    background-color: #e8e7e7;
}

.HomeMain_container .formWrapperMain .formInline .countDiv__column--col12 .MuiFormLabel-root {
    font-size: 18px;
    font-weight: 500;
    color: #000;
}


/* .formWrapperMain .formInline .countDiv__column--col12 .MuiFormLabel-root .MuiInputBase-root .MuiInputBase-root{
    background: #e8e7e7;
} */

.formWrapperMain .formHeader {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/formBanner.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3px;
    padding: 17px 12px;
    margin-bottom: 12px;
    border-bottom: solid 9px #ffe300;
}

.formWrapperMain .formHeader h2 {
    font-size: 29px;
    font-weight: 600;
    background: rgb(249, 249, 249);
    background: linear-gradient(180deg, rgba(249, 249, 249, 1) 0%, rgba(214, 215, 217, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    line-height: 37px;
}

.formWrapperMain .multipleCheckbox {
    width: 100%;
    margin-bottom: 23px;
    margin-top: 8px;
}

.submitbtnsection {
    width: 100%;
}

.formWrapperMain .multipleCheckbox .MuiFormControl-root {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
}

.formWrapperMain .multipleCheckbox .MuiFormControlLabel-root {
    width: 45%;
    font-size: 18px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin-bottom: -18px;
}

.formWrapperMain .multipleCheckbox .MuiFormLabel-root {
    font-size: 18px;
    font-weight: 500;
    color: #042241;
}

.formWrapperMain .submitbtnsection {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/FormButtonBnr.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    cursor: pointer;
}

.formWrapperMain .submitbtnsection button {
    display: flex;
    margin: 0 auto;
    width: 100%;
    /* cursor: pointer;  */
}

.formWrapperMain .submitbtnsection button span {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
    color: #fff
}


/* .formWrapperMain .state .MuiFormHelperText-root{
    color: #ff0000;
    position: absolute;
    right: 0;
    top: 28%;
} */

.formWrapperMain .MuiFormHelperText-root.Mui-error,
.formWrapperMain .MuiFormHelperText-root {
    color: #ff0000;
    position: absolute;
    right: 4%;
    top: 28%;
}

.formSction_subWrp {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
}

.leftSection_texts {
    width: 52%;
}

.formWrapMain {
    width: 46%;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/BIZOpp_human.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right top;
}

.lastSection_logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 13px solid #def5fc;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/footerMainBannr.webp);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 22px;
    position: relative;
}

.lastSection_logo .footerText span {
    background: rgb(250, 251, 252);
    background: linear-gradient(180deg, rgba(250, 251, 252, 1) 0%, rgba(168, 188, 199, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.lastSection_logo .footerText h1 {
    font-size: 43px;
    font-weight: 600;
    line-height: 36px;
    color: #ffe800;
    text-transform: uppercase;
    padding: 5px 4px;
}

.lastSection_logo .logWrp {
    width: 20%;
}

.lastSection_logo .footerText {
    width: 45%;
    margin-left: 28px;
}

.lastSection_logo .aspireLogo_wrpr {
    width: 12%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 999;
}

.lastSection_logo .aspireLogo_wrpr .logoOne {
    display: flex;
    flex-direction: column;
}

.lastSection_logo .aspireLogo_wrpr .logoOne {
    font-size: 24px;
    font-weight: 600;
    color: #ffe800;
    text-transform: uppercase;
}

.rightHuman_logo {
    position: absolute;
    right: 0;
    bottom: 0;
}

.bottomFooter_Block {
    padding: 10px 0 52px;
}

.HomeMain_container .footerblock {
    padding: 0;
}


/* =====================Form-text Sction==================== */

.formSction_maimWrp .formTxtWrpLeft h1 {
    font-size: 48px;
    font-weight: 700;
    color: #042241;
    line-height: 48px;
}

.formSction_maimWrp .formTxtWrpLeft p {
    font-size: 24px;
    font-weight: 500;
    color: #042241;
    line-height: 30px;
}

.formSction_maimWrp .formTxtWrpLeft h3 {
    font-size: 36px;
    font-weight: 600;
    color: #042241;
    line-height: 40px;
}

.formSction_maimWrp .yellowArrwtxt {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/yellowArrow.png);
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 27px;
    font-weight: 600;
}


/* /-------------------middleblock css start---------------/ */

.middle_lef_title_block h3 span {
    font-weight: 700;
}

.middle_top_heading {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    text-transform: uppercase;
    background: linear-gradient(180deg, rgba(145, 50, 29, 1) 22%, rgba(146, 49, 28, 1) 72%);
    padding: 8px 10px;
    text-align: center;
    border: 1px solid #fff;
    margin-bottom: 25px;
}

.home-middle-bck {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/middleblock_mainbg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px 0;
    padding-top: 35px;
}

.middleblock_flex_wrp {
    display: flex;
    justify-content: space-between;
}

.home-middle-inner_bck {
    border-bottom: 2px solid #dadbdd;
}

.middle_lef_title_block h1 {
    font-size: 36px;
    line-height: 48px;
    color: #882d19;
    font-weight: 700;
    text-transform: uppercase;
}

.middle_lef_title_block h3 {
    font-size: 36px;
    line-height: 48px;
    color: #193452;
    font-weight: 500;
    margin: 20px 0;
}

.right_top_left_title h2 {
    font-size: 30px;
    line-height: 42px;
    color: #fff;
    font-weight: 700;
    text-shadow: 0px 0px 1px rgb(81 67 21 / 80%), 0px 0px 7px rgb(81 67 21 / 80%), 0px 1px 2px rgb(81 67 21 / 80%);
}

.right_top_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.right_top_left_title {
    background: linear-gradient(180deg, rgba(145, 50, 29, 1) 22%, rgba(146, 49, 28, 1) 72%);
    background-repeat: no-repeat;
    padding: 15px;
    background-position: right bottom, center;
    background-size: cover;
    width: 67%;
    border: 1px solid #fff;
}

.quality_block h2 {
    font-size: 27px;
    line-height: 35px;
    color: #fff;
    font-weight: 600;
    background: #327843;
    padding: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;
}

.pointerText {
    cursor: pointer;
}

.commingSoonText {
    font-size: 32px;
    text-align: center;
    margin: 30px;
    line-height: 32px;
    font-weight: 600;
}

.quality_block {
    background: #eef3f6;
    padding: 10px;
    width: fit-content;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    margin-top: 5px;
    margin-bottom: 30px;
    cursor: pointer;
}

.right_top_right_logo {
    width: 27%;
}

.right_top_right_logo img {
    width: 100%;
    display: block;
}

.taxcredit_title h2 {
    font-size: 36px;
    line-height: 45px;
    color: #fff;
    font-weight: 600;
    background: linear-gradient(180deg, rgba(145, 50, 29, 1) 22%, rgba(146, 49, 28, 1) 72%);
    padding: 8px 10px;
}

.blocklist_wrapper {
    column-count: 2;
    column-gap: 40px;
    margin-top: 20px;
}

.blocklist_wrapper p {
    font-size: 24px;
    line-height: 28px;
    color: #011f40;
    font-weight: 500;
    position: relative;
    padding: 0 5px 5px 5px;
    ;
    padding-left: 30px;
    margin-top: 10px;
}

.blocklist_wrapper p::before {
    width: 18px;
    height: 18px;
    content: '';
    background: #882d19;
    position: absolute;
    left: 0;
    top: 5px;
}

.middle_left_block {
    width: 45%;
}

.middle_right_block {
    width: 52%;
}

.middle_btm_text h3 {
    font-size: 34px;
    line-height: 40px;
    color: #193452;
    font-weight: 500;
    margin: 20px 0;
    margin-top: 0;
}

.middle_btm_text h3 span {
    font-weight: 600;
}

.middle_lef_title_block {
    margin-top: 20px;
    width: 90%;
}

.middle_logo_wrapper {
    padding-bottom: 15px;
    border-bottom: 2px solid #dadbdd;
}

.benefit_block {
    padding: 10px;
    background: #fff;
    box-shadow: 0 15px 25px 4px #ccd4d6;
}

.inner_container {
    background: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/middleblock_bottom_redbg.webp), #932716;
    background-repeat: no-repeat;
    background-size: cover, cover;
    background-position: right bottom, center;
    width: 12%;
    padding: 1%;
}

.inner_container p {
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    font-weight: 600;
    text-align: left;
}

.middleblock_bottom h2 {
    font-size: 35px;
    line-height: 40px;
    color: #983825;
    font-weight: 700;
    margin-bottom: 15px;
    margin-top: 20px;
}

.benefit_flex_block {
    display: flex;
    justify-content: space-between;
}

.qualify_block h1 {
    font-size: 52px;
    line-height: 55px;
    color: #fff;
    font-weight: 600;
    background: #327843;
    padding: 8px 20px;
    text-align: center;
}

.qualify_block {
    background: #eef3f6;
    padding: 10px;
    box-shadow: 0 15px 25px 4px #ccd4d6;
    margin-top: 35px;
    cursor: pointer;
}

.container_wrapper {
    justify-content: center;
}

.bottom_bold_text {
    font-weight: 600;
    margin: 15px 0;
}

.click_linked {
    text-decoration: underline;
    color: inherit;
}


/* /-------------------middleblock css end---------------/ */


/* /-------------------middleblock responsive start---------------/ */

@media only screen and (max-width: 1820px) {
    .qualify_block h1 {
        font-size: 40px;
        line-height: 48px;
    }
    .generic_banner .topRight_text h2 {
        font-size: 26px;
        line-height: 30px;
    }
    .generic_banner .topRight_text h1 {
        font-size: 31px;
        line-height: 34px;
    }
}

@media only screen and (max-width: 1720px) {
    .casey_banner .topRight_text h1 {
        font-size: 28px;
        line-height: 32px;
    }
    .casey_banner .topRight_text h2 {
        font-size: 21px;
        line-height: 25px;
    }
}

@media only screen and (max-width: 1699px) {
    .blocklist_wrapper p {
        font-size: 22px;
        line-height: 26px;
    }
    .HomeMain_container .topRight_text {
        padding: 13px !important;
    }
    .middle_lef_title_block h3 {
        font-size: 24px;
        line-height: 30px;
    }
    .budchallan_banner .topRight_text {padding: 16px !important;}
    .middle_btm_text h3 {
        font-size: 24px;
        line-height: 30px;
    }
    .georgedan_banner_top .george_title {width: 75%;}
    .right_top_left_title h2 {
        font-size: 28px;
        line-height: 40px;
    }
    .quality_block h2 {
        font-size: 22px;
        line-height: 30px;
    }
    .taxcredit_title h2 {
        font-size: 30px;
        line-height: 40px;
    }
    .HomeMain_container .topRight_text {
        background: #022140 !important;
        padding: 13px;
    }
    .mike_banner .stuker_right {
        background: none;
    }
    .restaurant_banner .topRight_text {
        width: 78%;
    }
}

@media only screen and (max-width: 1599px) {
    .lastSection_logo .footerText {
        width: 35%;
        margin-right: 28px;
    }
    .lastSection_logo .aspireLogo_wrpr {
        width: 14%;
    }
    .georgefooter_block .george_footer_text h1 {
        font-size: 25px !important;
        line-height: 26px !important;
    }
    .budchallan_banner .stuker_left_part2 span {
        font-size: 20px;
        line-height: 20px;
    }
}

@media only screen and (max-width: 1536px) {
    .inner_container .MuiPaper-root p {
        font-size: 20px;
        line-height: 25px;
    }
}

@media only screen and (max-width: 1470px) {
    .morhea_footer .bottom_banner_jack_block1 h1 {
        font-size: 22px !important;
        line-height: 26px !important;
    }
    .HomeMain_container .topRight_text h1 {
        font-size: 24px;
        line-height: 28px;
    }
}

@media only screen and (max-width: 1460px) {
    .middle_top_heading {
        font-size: 40px;
        line-height: 43px;
    }
    .middle_lef_title_block h1 {
        font-size: 40px;
        line-height: 43px;
    }
    .right_top_left_title h2 {
        font-size: 24px;
        line-height: 35px;
    }
    .quality_block h2 {
        font-size: 20px;
        line-height: 26px;
    }
    .taxcredit_title h2 {
        font-size: 27px;
        line-height: 35px;
    }
    .qualify_block h1 {
        font-size: 33px;
        line-height: 40px;
    }
    .casey_banner .topRight_text h2 {
        font-size: 24px;
        line-height: 27px;
    }
    .casey_banner .topRight_text h1 {
        font-size: 25px !important;
        line-height: 30px !important;
    }
}

@media only screen and (max-width: 1399px) {
    .inner_container p {
        font-size: 19px;
        line-height: 24px;
    }
}

@media only screen and (max-width: 1380px) {
    .quality_block h2 {
        font-size: 16px;
        line-height: 22px;
    }
    .quality_block {
        margin-bottom: 20px;
    }
    .blocklist_wrapper p {
        margin-top: 0;
    }
    .taxcredit_title h2 {
        font-size: 22px;
        line-height: 32px;
    }
}

@media only screen and (max-width: 1340px) {
    .generic_banner .topRight_text h1 {
        font-size: 35px !important;
        line-height: 38px !important;
    }
}

@media only screen and (max-width: 1199px) {
    .right_top_right_logo {
        width: auto;
    }
    .bottom_banner_jack {
        margin-top: 10px;
    }
    .middleblock_flex_wrp {
        flex-direction: column;
    }
    .middle_left_block {
        width: 100%;
    }
    .middle_lef_title_block {
        width: 100%;
    }
    .middle_right_block {
        width: 100%;
    }
    .right_top_block {
        flex-direction: column-reverse;
    }
    .right_top_left_title {
        width: 96%;
        padding: 2%;
        margin-top: 20px;
    }
    .quality_block {
        width: 98%;
        padding: 1%;
        margin: 15px 0;
    }
    .blocklist_wrapper p::before {
        top: 2px;
    }
    .taxcredit_title h2 {
        width: 96%;
        padding: 2%;
    }
    .middle_logo_wrapper {
        text-align: center;
    }
    .home-middle-bck {
        padding: 20px 0;
    }
    .inner_container .MuiPaper-root {
        height: 65%;
    }
    .qualify_block h1 {
        font-size: 26px;
        line-height: 35px;
    }
    .middleblock_bottom h2 {
        font-size: 26px;
        line-height: 35px;
        margin: 20px 0;
    }
    .blocklist_wrapper p {
        font-size: 18px;
        line-height: 22px;
    }
    .benefit_block {
        padding: 5px;
    }
    .benefit_flex_block {
        flex-wrap: wrap;
    }
    .inner_container {
        flex: 1 0 21%;
        margin: 0.5%;
        padding: 1.5%;
    }
    .qualify_block {
        margin-top: 10px;
    }
    .blocklist_wrapper {
        padding-bottom: 15px;
    }
    .caseyfooter_block .bottom_bannerPart2 .bottom_banner_jack_block1:nth-of-type(1) {
        width: auto;
    }
    .mary_banner .stuker_right {
        width: auto;
    }
    .mary_banner .stuker_left {
        width: auto;
        margin-bottom: 5px;
        padding: 10px;
    }
    .mary_banner .stuker_left img {
        width: auto;
    }
    .happiness_banner .stuker_left_part1 img {
        margin: 0 auto;
    }
    .happiness_banner .stuker_left_part1 {
        width: 26%;
        margin: 10px 0;
        background: #fff;
    }
    .simpsonfooter_block .blackbg_logo img {
        margin: 0 auto;
    }
    .bottom_bold_text {
        margin-top: 0;
        font-size: 25px;
        line-height: 28px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 999px) {
    .casey_banner .stuker_left_part1 {
        width: 98%;
        padding: 1%;
    }
    .happiness_banner .stuker_left_part1 {width: auto;}
    .happiness_banner .stuker_left_part1 img {width: auto;}
    .budchallan_banner .stuker_left_part2 {width: auto;}
}

@media only screen and (max-width: 899px) {
    .inner_container .MuiPaper-root {
        height: 72%;
    }
    .inner_container .MuiPaper-root p {
        font-size: 18px;
        line-height: 20px;
    }
}

@media only screen and (max-width: 699px) {
    .inner_container {
        flex: 1 0 23%;
        padding: 20px;
    }
    .inner_container p {
        font-size: 16px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 640px) {
    .middle_top_heading {
        font-size: 28px;
        line-height: 30px;
    }
    .happiness_banner .topRight_text h1 {
        font-size: 28px !important;
        line-height: 30px !important;
    }
    .HomeMain_container .topRight_text h1 span {
        font-size: 28px;
        line-height: 30px;
    }
    .HomeMain_container .topRight_text h1 {
        font-size: 28px !important;
line-height: 30px !important;
    }
    .peter_banner .topRight_text h1 { font-size: 28px !important;
        line-height: 30px !important;}
    .HomeMain_container .topRight_text h2 {
        font-size: 26px !important;
        line-height: 30px !important;
    }
    .middle_lef_title_block h1 {
        font-size: 28px;
        line-height: 30px;
    }
    .leftSection_texts h2 {
        font-size: 28px !important;
        line-height: 30px !important;
    }
    .leftSection_texts h3 {
        font-size: 26px !important;
        line-height: 28px !important;
    }
    .leftSection_texts_con2 label {
        font-size: 28px !important;
        line-height: 30px !important;
    }
    .unilityBlock_heading h2 {
        font-size: 28px !important;
        line-height: 30px !important;
    }
    .bottom_banner_jack_block1:nth-of-type(2) h1 {
        font-size: 28px !important;
        line-height: 30px !important;
    }
}

@media only screen and (max-width: 599px) {
    .inner_container .MuiPaper-root {
        height: auto;
    }
    .benefit_block {
        padding: 10px;
    }
}

@media only screen and (max-width: 520px) {
    .blocklist_wrapper {
        column-count: 1;
    }
    .inner_container {
        flex: 1 0 93%;
        padding: 3%;
    }
}

@media only screen and (max-width: 499px) {
    .middle_lef_title_block h3 {
        font-size: 18px;
        line-height: 23px;
        margin: 10px 0;
    }
    .middle_btm_text h3 {
        font-size: 18px;
        line-height: 23px;
    }
    .bottom_bold_text {
        font-size: 18px;
        line-height: 23px;
    }
    .right_top_left_title h2 {
        font-size: 18px;
        line-height: 26px;
    }
    .quality_block h2 {
        font-size: 18px;
        line-height: 22px;
        padding: 10px;
    }
    .taxcredit_title h2 {
        font-size: 18px;
        line-height: 24px;
    }
    .middle_logo_wrapper {
        width: 96%;
        margin: 0 auto;
    }
    .middleblock_bottom h2 {
        font-size: 22px;
        line-height: 27px;
    }
    .qualify_block h1 {
        font-size: 22px;
        line-height: 26px;
    }
    .qualify_block {
        margin-top: 20px;
    }
    .formWrapperMain .multipleCheckbox .MuiFormControlLabel-root {
        display: block;
    }
    .formWrapperMain .multipleCheckbox .MuiFormControlLabel-root .MuiCheckbox-root {
        padding: 4px;
        padding-left: 0;
    }
    .bottom_banner_jack_block1:nth-of-type(3) {display: block !important;}
}


/* /-------------------middleblock responsive end---------------/ */


/* /-------------------homeutilityblock css start---------------/ */

.unilityBlock_heading h2 {
    font-size: 36px;
    line-height: 42px;
    color: #fff;
    font-weight: 500;
    background: #003c80;
    padding: 10px 20px;
}

.unilityBlock_heading {
    width: 570px;
    padding: 10px;
    background: #ffffff7d;
    padding-bottom: 0;
}

.yellow_text {
    color: #fefe01;
}

.utility_box_container p {
    font-size: 30px;
    line-height: 38px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    text-shadow: 0px 0px 1px rgb(81 67 21 / 45%), 0px 0px 7px rgb(81 67 21 / 80%), 0px 1px 2px rgb(81 67 21 / 15%);
}

.utility_box_block {
    background: #ffffff7d;
    overflow: hidden;
    margin-bottom: 25px;
    box-shadow: 0px 2px 6px 6px #0000001f;
}

.utility_box_container {
    background: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/blue_inner_bg.webp), linear-gradient(180deg, rgba(44, 177, 235, 1) 22%, rgba(1, 139, 200, 1) 72%);
    background-repeat: no-repeat;
    background-size: cover, cover;
    background-position: right bottom, center;
    padding: 0.5% !important;
    border: 1px solid #fff;
    border-radius: 0 !important;
    width: 23.5%;
    margin: 0.1%;
}

.utility_flexbox_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 5px;
}

.yellow_top_heading {
    font-size: 30px;
    line-height: 38px;
    font-weight: 500;
    color: #fefe01;
}

.utilityblock_bottom_flex {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
}

.utilityblock_bottom_flex p {
    font-size: 26px;
    line-height: 30px;
    color: #fff;
    font-weight: 500;
    position: relative;
    padding-left: 30px;
    margin-right: 18px;
}

.utilityblock_bottom_flex p::before {
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    background: #0df;
    top: 6px;
    left: 0;
}

.utilityblock_bottom {
    background: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/blue_repeat_bg.png), linear-gradient(180deg, rgba(44, 177, 235, 1) 22%, rgba(1, 139, 200, 1) 72%);
    background-repeat: no-repeat;
    background-size: cover;
    ;
    padding: 15px 10px 50px 30px;
    margin: 0.5%;
    margin-top: 0.2%;
}

.services_flex {
    display: flex;
    justify-content: space-between;
    margin: 0.5%;
    margin-top: 35px;
}

.services_inner_wrp h2 {
    font-size: 36px;
    line-height: 40px;
    font-weight: 500;
    color: #0df;
    padding-bottom: 15px;
    border-bottom: 2px solid #193c61;
}

.services_inner_wrp p {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    color: #fff;
    margin: 25px 0;
    position: relative;
    padding-left: 25px;
}

.services_inner_wrp {
    background: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/services_blue_bg.webp), #05274b;
    width: 31%;
    padding: 1%;
    padding-bottom: 50px;
    background-position: right bottom;
    border: 1px solid #fff;
    background-size: cover;
}

.services_inner_wrp p::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0;
    top: 6px;
    background: #0df;
}


/* /-------------------homeutilityblock css end---------------/ */

@media only screen and (max-width: 1599px) {
    .utility_box_container p {
        font-size: 23px;
        line-height: 28px;
    }
    .yellow_top_heading {
        font-size: 23px;
        line-height: 28px;
    }
    .utilityblock_bottom_flex p {
        font-size: 22px;
        line-height: 28px;
    }
    .utilityblock_bottom_flex p::before {
        width: 16px;
        height: 16px;
        top: 5px;
    }
    .services_inner_wrp h2 {
        font-size: 30px;
        line-height: 36px;
    }
    .services_inner_wrp p {
        font-size: 22px;
        line-height: 28px;
    }
}

@media only screen and (max-width: 1340px) {
    .services_inner_wrp h2 {
        font-size: 27px;
        line-height: 30px;
    }
}
@media only screen and (max-width: 1299px) {
    .peter_banner .stuker_left_part3 {width: 42%;}
    .peter_banner .stuker_left_part2 {width: 28%;}
    .peter_banner .stuker_left_part1 {width: 27%;}


}
@media only screen and (max-width: 1199px) {
    .utility_box_container p {
        font-size: 20px;
        line-height: 24px;
    }
    .utility_box_container {
        padding: 1.5% !important;
        width: 29.5%;
    }
    .unilityBlock_main {
        margin-top: 25px;
    }
    .utilityblock_bottom_flex p {
        font-size: 20px;
        line-height: 24px;
    }
    .yellow_top_heading {
        font-size: 20px;
        line-height: 24px;
    }
    .utilityblock_bottom {
        padding: 10px;
        margin: 1%;
    }
    .services_flex {
        flex-wrap: wrap;
    }
    .services_inner_wrp {
        flex: 1 0 45%;
    }
    .services_flex {
        margin-top: 10px;
        margin: 1%;
    }
    .services_inner_wrp p {
        font-size: 20px;
        line-height: 24px;
    }
    .services_inner_wrp p::before {
        top: 5px;
    }
    .services_inner_wrp {
        padding: 1.5%;
    }
    .georgefooter_block .lastSection_logo .aspireLogo_wrpr .logoOne {
        display: block;
    }
    .peter_banner .stuker_right {
        width: auto;
    }
    .peter_banner .stuker_left {
        width: auto;
    }
    .peter_banner .stuker_left img {width: 100%;}
    .morhea_footer .bottom_bannerPart2 .blackbg_logo img {width: 200px !important;}
    .morhea_footer .blackbg_logo {background: transparent;border: none;}
}

@media only screen and (max-width: 991px) {
    .utility_box_container {
        width: 46.7%;
        padding: 1% !important;
    }
    .unilityBlock_heading {
        width: 98%;
        padding: 1%;
    }
    .unilityBlock_heading h2 {
        font-size: 30px;
        line-height: 36px;
        text-align: center;
    }
    .utilityblock_bottom_flex p::before {
        top: 4px;
    }

}

@media only screen and (max-width: 699px) {
    .services_inner_wrp {
        flex: 1 0 92%;
        padding: 2.5%;
    }
    .services_inner_wrp p::before {
        top: 4px;
    }
}

@media only screen and (max-width: 499px) {
    .utility_box_container {
        width: 94%;
        padding: 2% !important;
    }
    .utility_box_container p {
        font-size: 18px;
        line-height: 22px;
    }
    .utilityblock_bottom_flex p {
        font-size: 18px;
        line-height: 22px;
    }
    .yellow_top_heading {
        font-size: 18px;
        line-height: 22px;
    }
    .services_inner_wrp p {
        font-size: 18px;
        line-height: 22px;
    }
  
}


/* =====================Form-text Sction==================== */

.bottom_banner_jack {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 12px solid #def5fc;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/footerMainBannr.webp);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 22px;
    position: relative;
    height: 209px;
    margin-top: 40px;
}

.bottom_banner_jack_IMG {
    position: absolute;
    right: 0;
    top: -45px;
}

.bottom_banner_jack_block1:nth-of-type(1) {
    width: 18%;
}

.bottom_banner_jack_block1:nth-of-type(1) span {
    display: block;
    padding: 10px;
    background: rgb(253, 254, 254);
    background: linear-gradient(180deg, rgba(253, 254, 254, 1) 0%, rgba(209, 213, 215, 1) 100%);
}

.bottom_banner_jack_block1:nth-of-type(1) span img {
    width: 100%;
}

.bottom_banner_jack_block1:nth-of-type(2) {
    width: 38%;
    margin: 0 2%;
}

.bottom_banner_jack_block1:nth-of-type(2) h1 {
    margin: 0;
    padding: 0;
    color: #e7ecef;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
}

.bottom_banner_jack_block1:nth-of-type(2) h1 span {
    color: #ffe800;
}

.bottom_banner_jack_block1:nth-of-type(3) {
    width: 12%;
    margin: 0 2%;
    position: relative;
    z-index: 2;
}

.bottom_banner_jack_block1:nth-of-type(3) span {
    padding: 10px;
    background: rgb(253, 254, 254);
    background: linear-gradient(180deg, rgba(253, 254, 254, 1) 0%, rgba(209, 213, 215, 1) 100%);
    display: flex;
}

.bottom_banner_jack_block1:nth-of-type(3) span img {
    width: 100%;
}

.bottom_banner_jack_block1:nth-of-type(3) label {
    display: block;
    margin: 0;
    font-size: 20px;
    text-transform: uppercase;
    color: #ffe800;
    font-weight: bold;
}

.bottom_bannerPart2 .bottom_banner_jack_block1:nth-of-type(1) {
    width: 10%;
}

.bottom_bannerPart2 .bottom_banner_jack_block1:nth-of-type(1) div img {
    width: 100%;
}

.fbanner_leftText {
    margin: 0px 0 5px 0;
    background: rgb(253, 254, 254);
    background: linear-gradient(180deg, rgba(253, 254, 254, 1) 0%, rgba(209, 213, 215, 1) 100%);
    border-radius: 4px;
}

.fbanner_leftText span {
    font-size: 14px;
    color: #053162;
    text-shadow: 0 0 5px #fff;
    font-weight: bold;
    text-align: center;
    padding: 5px!important;
    text-transform: uppercase;
    line-height: 14px;
    width: 100px;
    margin: 0 auto;
}

.fbanner_leftlogo {
    display: block;
    padding: 10px;
    background: rgb(253, 254, 254);
    background: linear-gradient(180deg, rgba(253, 254, 254, 1) 0%, rgba(209, 213, 215, 1) 100%)
}

.stuker_banner_con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.stuker_left {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.stuker_right {
    width: 54.5%;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/stuker_top_banner_BG.webp);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    border: 1px solid #ffffffad;
}

.stuker_left_part1 {
    width: 27%;
    background-color: #0f3f69;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: solid 2px #fff;
}

.stuker_left_part1 img {
    display: block;
}

.stuker_left_part2 {
    width: 28%;
    border: solid 2px #fff;
    border-radius: 5px;
    background: rgb(173, 192, 202);
    background: linear-gradient(180deg, rgba(173, 192, 202, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(173, 192, 202, 1) 100%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.stuker_left_part2 span {
    font-size: 25px;
    color: #053162;
    text-shadow: 0 0 5px #fff;
    font-weight: bold;
    text-align: center;
    padding: 5px!important;
    text-transform: uppercase;
    line-height: 30px;
}

.stuker_left_part3 {
    width: 42%;
    border-radius: 5px;
    border: solid 2px #fff;
    background: rgb(173, 192, 202);
    background: linear-gradient(180deg, rgba(173, 192, 202, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(173, 192, 202, 1) 100%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.stuker_left_part3 img {
    max-width: 90%;
    border-radius: 5px;
}

.stuker_right_text {
    width: auto!important;
    background: none!important;
    padding: 20px !important;
}


/* ============Media Css============================== */

@media only screen and (max-width: 1820px) {
    .commonWidth {
        width: 96% !important;
    }
    .HomeMain_container .bannerBottomInner p {
        font-size: 35px;
    }
    .budchallan_banner .topRight_text h2 {
        font-size: 31px;
        line-height: 33px;
    }
    .quality_block h2 {
        font-size: 25px;
        line-height: 30px;
    }
    .Zufelt_banner .topRight_text {width: 75%;}
    .restaurant_banner .topRight_text h1 {
        font-size: 26px;
        line-height: 29px;
    }
    .restaurant_banner .topRight_text h2 {
        font-size: 24px;
        line-height: 26px;
    }
    .taxcredit_title h2 {
        font-size: 28px;
        line-height: 42px;
    }
}

@media only screen and (max-width: 1799px) {
.georgedan_banner_top .topRight_text h1 {
    font-size: 28px;
    line-height: 32px;
}
}

@media only screen and (max-width: 1656px) {
    .formWrapMain {
        width: 48%;
    }
    .HomeMain_container .asPireText h1 {
        font-size: 42px;
        ;
    }
    .HomeMain_container .asPireText h3 {
        font-size: 26px;
    }
    .HomeMain_container .asPireText p {
        font-size: 20px;
        width: 80%;
    }
    .HomeMain_container .bannerBottomInner p {
        font-size: 30px;
        padding: 16px 5px;
    }
    .bottom_banner_jack_block1:nth-of-type(2) h1 {
        font-size: 30px;
        line-height: 31px;
    }
    .stuker_left_part2 span {
        font-size: 20px;
        line-height: 20px;
    }
    .peter_footer .bottom_banner_jack_block1:nth-of-type(2) {width: 41%;}
    .peter_footer .bottom_banner_jack_block1:nth-of-type(2) h1 {font-size: 25px;line-height: 28px;}
}

@media only screen and (max-width: 1740px) {
    .happiness_banner .topRight_text h2 {
        font-size: 24px;
        line-height: 28px;}
        .happiness_banner .topRight_text h1 {
            font-size: 28px;
            line-height: 34px;
        }
        .restaurant_footer_block .george_footer_text h1 {    font-size: 24px!important;
            line-height: 28px!important;}
}

@media only screen and (max-width: 1520px) {
    .HomeMain_container .topRight_text h1 {
        font-size: 31px;
        line-height: 34px;
    }
    .generic_banner .topRight_text h1 {font-size: 28px !important;line-height: 32px !important;}
    .peter_banner .topRight_text h1 {font-size: 24px;line-height: 26px;}
    .peter_banner .topRight_text h1 {font-size: 24px;line-height: 26px;}
    .peter_banner .stuker_left_part2 span {font-size: 24px;line-height: 28px;}
    .peter_banner .topRight_text h2 {font-size: 19px;line-height: 22px;margin-top: 5px;}
}

@media only screen and (max-width: 1440px) {
    .happiness_banner .topRight_text h1 {
        font-size: 27px !important;
        line-height: 30px !important;
    }
    .services_inner_wrp {padding-bottom: 1%;}
}

@media only screen and (max-width: 1462px) {
    .HomeMain_container .asPireText .stePWrp {
        font-size: 26px;
    }
    .HomeMain_container .asPireText h3 {
        font-size: 22px;
    }
    .HomeMain_container .asPireText p {
        font-size: 18px;
        line-height: 25px;
    }
    .HomeMain_container .asPireText h1 {
        font-size: 36px;
    }
    .HomeMain_container .bannerBottomInner p {
        font-size: 26px;
    }
    .HomeMain_container .topRight_text h1 {
        font-size: 30px;
        line-height: 32px;
    }
    .HomeMain_container .topRight_text h2 {
        font-size: 28px;
        line-height: 32px;
    }
    .bottom_banner_jack_block1:nth-of-type(2) h1 {
        font-size: 27px;
        line-height: 27px;
    }
    .bottom_bannerPart2 .bottom_banner_jack_block1:nth-of-type(1) {
        width: 12%;
    }
    .HomeMain_container .topRight_text h2 {
        font-size: 22px;
        line-height: 25px;
    }
    .generic_banner .topRight_text h1 {font-size: 27px !important;line-height: 30px !important;}
    .peter_banner .topRight_text h1 {font-size: 23px;line-height: 26px;}
    .peter_banner .topRight_text h2 {font-size: 20px;line-height: 22px;}

}
@media only screen and (max-width: 1360px) {
.formWrapperMain .multipleCheckbox .MuiFormControlLabel-root .MuiCheckbox-root {padding-right: 2px;}
.formWrapperMain .multipleCheckbox .MuiFormControlLabel-root {margin-right: 0;}
.formWrapperMain .multipleCheckbox .MuiFormControlLabel-root .MuiTypography-root {font-size: 14px;font-weight: 500;}
}
@media only screen and (max-width: 1350px) {
    .HomeMain_container .topRight_text h1 {
        font-size: 26px !important;
        line-height: 29px !important;
    }
    .georgedan_banner_top .topRight_text h1 {font-size: 27px;line-height: 30px;}
    .georgedan_banner_top .topRight_text h2 {font-size: 22px;line-height: 27px;}
    .happiness_banner .topRight_text h1 {font-size: 24px !important;line-height: 27px !important;}
    .happiness_banner .topRight_text h2 {font-size: 20px !important;line-height: 22px !important;}
.stuker_banner .topRight_text h2 {font-size: 22px !important;line-height: 28px !important;}
.stuker_banner .topRight_text h1 {font-size: 24px !important;line-height: 29px !important;}
.Zufelt_banner .topRight_text h1 {font-size: 28px !important;line-height: 31px !important;}
.Zufelt_banner .topRight_text h2 {font-size: 24px !important;line-height: 29px !important;}
.peter_banner .topRight_text h2 {font-size: 19px !important;line-height: 22px !important;}
/* .peter_banner .topRight_text h1 span {font-size: 21px !important;line-height: 25px !important;} */
.peter_banner .stuker_left_part2 span {font-size: 20px;line-height: 24px;}
    .HomeMain_container .topRight_text h2 {
        font-size: 27px;
        line-height: 30px;
    }
    .casey_banner .topRight_text h2 {
        font-size: 24px !important;
        line-height: 27px !important;
    }
}

@media only screen and (max-width: 1320px) {
    .budchallan_banner .topRight_text h2 {
        font-size: 31px;
        line-height: 33px;
    }
    .restaurant_footer_block .george_footer_text h1 {    font-size: 21px!important;
        line-height: 25px!important;}

}

@media only screen and (max-width: 1300px) {
    .stuker_left_part2 span {
        font-size: 16px;
        line-height: 20px;
    }
    .HomeMain_container .asPireText h3 {
        font-size: 20px;
        margin-top: -10px;
    }
    .bottom_banner_jack_block1:nth-of-type(2) {
        width: 37%;
        margin: 0 2%;
    }
}

@media only screen and (max-width: 1199px) {
    .leftSection_texts_logo {
        margin: 0 auto;
        display: block;
    }
    .leftSection_texts h2 {
        margin: 25px 0 0 0;
    }
    .footer_image_wrp {display: none;}
    .peter_footer .bottom_banner_jack_block1:nth-of-type(2) {width: auto;}
    /* .budchallan_footer .bottom_bannerPart2 .bottom_banner_jack_block1:nth-of-type(1) div img {width: 200px;} */
    .stuker_banner_con {
        display: block;
    }
    .stuker_left_part2 span {
        font-size: 20px;
        line-height: 25px;
    }
    .casey_banner .stuker_left {width: auto;}
    .casey_banner .stuker_right {width: auto;}
    .stuker_left {
        width: auto;
    }
    .stuker_left_part1 img {
        margin: 0 auto;
    }
    .stuker_right {
        width: auto;
    }
    .bottom_bannerPart2 .bottom_banner_jack_block1:nth-of-type(1) {
        width: auto;
    }
    .bottom_bannerPart2 .bottom_banner_jack_block1:nth-of-type(1) div img {
        width: auto;
        max-width: 90%;
    }
    .fbanner_leftText span {
        width: auto;
        padding: 15px!important;
    }
    .bottom_banner_jack {
        display: block;
        height: auto;
        text-align: center;
        margin-top: 25px;
    }
    .bottom_banner_jack_IMG {
        display: none;
    }
    .bottom_banner_jack_block1:nth-of-type(1) {
        width: auto;
    }
    .bottom_banner_jack_block1:nth-of-type(2) {
        width: auto;
        margin: 25px 0;
    }
    .bottom_banner_jack_block1:nth-of-type(3) {
        width: 600px;
        margin: 15px 0;
        display: flex;
        justify-content: center;
    margin: 0 auto;
    }
    .bottom_banner_jack_block1:nth-of-type(3) div:first-child {margin: 10px;}
    .bottom_banner_jack_block1:nth-of-type(3) div:last-child {margin: 10px;}

    .bottom_banner_jack_block1:nth-of-type(1) span img {
        width: auto;
        max-width: 100%;
    }
    .bottom_banner_jack_block1:nth-of-type(3) span img {
        width: 100%;
        max-width: inherit;

    }
    .bottom_banner_jack_block1:nth-of-type(3) div:first-child {
        margin-bottom: 15px;
    }
    .bottom_banner_jack_block1:nth-of-type(3) span {
        display: inline-block;
        line-height: 120px;
        height: 80px;
    }
    .bottom_banner_jack_block1:nth-of-type(1) span {
        display: inline-block;
    }
    .HomeMain_container .bannerMiddle {
        flex-flow: column wrap;
    }
    .HomeMain_container .videoWrapper {
        width: 98.2%;
        margin: 0 auto;
    }
    .HomeMain_container .rightcontent_Wrapper {
        width: 100%;
        margin-top: 20px;
        flex-wrap: wrap;
    }
    .HomeMain_container .asPireStep_Block {
        width: 47.5%;
    }
    .HomeMain_container .bannerBottomInner p {
        font-size: 31px;
        line-height: 35px;
    }
    .HomeMain_container .bannerMiddle {
        margin: 20px 0 0;
    }
    .HomeMain_container .homeBanner_Main {
        padding: 20px 0;
    }
    .HomeMain_container .bannerTop {
        display: block;
        text-align: center;
    }
    .HomeMain_container .topRight_text {
        width: auto;
    }
    .HomeMain_container .topLogo {
        width: auto;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 999.98px) {
    .stuker_left {
        display: block;
    }
    .stuker_left div {
        display: block;
        width: 100%;
    }
    .casey_banner .stuker_left_part3 {
        width: auto;
    }
    .stuker_left_part2 span {
        font-size: 24px;
        display: block;
        line-height: 30px;
        padding: 15px!important;
    }
    .stuker_left_part2 span br {
        display: none;
    }
    .HomeMain_container .videoWrapper {
        width: 97.6%;
    }
    .peter_banner .stuker_left_part1 {width: auto;}
.peter_banner .stuker_left img {width: auto;}
.peter_banner .stuker_left_part2 {width: auto;}
.peter_banner .stuker_left_part3 {width: auto;}
}

@media only screen and (max-width: 850px) {
    .HomeMain_container .videoWrapper {
        width: 97.2%;
    }
}

@media only screen and (max-width: 750px) {
    .HomeMain_container .asPireStep_Block {
        width: 100%;
        margin-bottom: 20px;
    }
    .HomeMain_container .asPireStep_BlockWrp {height: auto;}
    .HomeMain_container .videoWrapper {
        width: 96.8%;
    }
    .HomeMain_container .bannerBottomInner p {
        font-size: 25px;
    }
    .bottom_banner_jack_block1:nth-of-type(3) span {height: 70px;}
    .bottom_banner_jack_block1:nth-of-type(3) {width: 500px;}
}
@media only screen and (max-width: 640px) {
    .bottom_banner_jack_block1:nth-of-type(3) {width: auto;flex-direction: column;}
    .bottom_banner_jack_block1:nth-of-type(3) span {height: auto;line-height: 0;}

}
@media only screen and (max-width: 600px) {
    .HomeMain_container .videoWrapper {
        width: 96%;
    }
    .HomeMain_container .bannerBottomInner p {
        font-size: 20px;
        line-height: 28px;
    }
}

@media only screen and (max-width: 550px) {
    .HomeMain_container .videoWrapper {
        width: 95.6%;
    }
}

@media only screen and (max-width: 500px) {
    .HomeMain_container .videoWrapper {
        width: 95%;
    }
    .HomeMain_container .bannerBottomInner p {
        font-size: 23px;
    }
}

@media only screen and (max-width: 400px) {
    .HomeMain_container .videoWrapper {
        width: 93.7%;
    }
}


/* ------------------EXPENSE REDUCTION CSS Starts------------------ */


/* .expns_rdctn_mainwrapper {background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/Home_MainBanner.webp);background-repeat: no-repeat;background-size: cover;background-position: center;} */

.expns_rdctn_wrap {
    padding: 30px 8px 12px;
    background-color: #ffffff7d;
    box-shadow: -1px 1px 6px 0px rgb(0 0 0 / 18%);
}

.table_main_wrapper {
    margin-top: 35px;
    border: 5px solid #b1cbdb;
    border-radius: 10px;
}

.expns_rdctn_wrap>h2 {
    font-size: 48px;
    font-weight: bold;
    color: #042241;
    text-transform: uppercase;
}

.expns_rdctn_wrap>h4 {
    font-size: 34px;
    line-height: 36px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    margin-top: 20px;
    padding: 18px 18px;
    background: linear-gradient(180deg, rgba(44, 177, 235, 1) 33%, rgba(1, 139, 200, 1) 72%);
    border-image: linear-gradient(#37617b, #37617b) 30;
    border-style: solid;
    border-width: 1px;
}

.color_indicator {
    display: flex;
    margin-top: 25px;
}

.color_indicator p {
    font-size: 21px;
    font-weight: 500;
    color: #000000;
    padding-left: 130px;
    position: relative;
}

.color_indicator p::after {
    content: '';
    position: absolute;
    height: 50px;
    width: 108px;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    border-radius: 3px;
}

.color_indicator p:nth-child(1) {
    width: 24%;
}

.color_indicator p:nth-child(2) {
    width: 19%;
}

.color_indicator p:nth-child(3) {
    width: 14%;
}

.color_indicator p:nth-child(1)::after {
    background-color: #abf55b;
    border: 2px solid #11b767;
}

.color_indicator p:nth-child(2)::after {
    background-color: #83adc8;
    border: 2px solid #668da6;
}

.color_indicator p:nth-child(3)::after {
    background-color: #ffcf84;
    border: 2px solid #c19c64;
}

.color_indicator p:not(:last-child) {
    margin-right: 65px;
}

.table_heading_row_wrapper .table_row_wrapper p {
    background-color: #013b7f;
    color: #fff;
    font-size: 27px;
    font-weight: bold;
    line-height: 32px;
}

.table_desc_row_wrapper .table_row_wrapper p {
    font-size: 16px;
}

.table_desc_row_wrapper .table_row_wrapper p:first-child span {
    font-size: 20px;
    line-height: 26px;
}

.table_desc_row_wrapper .table_row_wrapper p {
    background-color: #b1cbdb;
}

.table_desc_row_wrapper .table_row_wrapper:nth-of-type(even) p span label {
    background-color: #fff;
}

.table_row_wrapper {
    display: flex;
    justify-content: space-between;
}

.table_row_wrapper p {
    padding: 4px;
    display: table;
    justify-content: center;
    align-items: center;
}

.table_row_wrapper p span {
    display: table-row;
    width: 100%;
    height: 100%;
    text-align: center;
}

.table_row_wrapper p span label {
    padding: 10px;
    display: table-cell;
    vertical-align: middle;
}

.table_row_wrapper p:nth-of-type(1) {
    width: 8%;
    padding: 0;
}

.table_row_wrapper p:nth-of-type(2) {
    width: 10%;
}

.table_row_wrapper p:nth-of-type(3) {
    width: 16%;
}

.table_row_wrapper p:nth-of-type(4) {
    width: 8%;
}

.table_row_wrapper p:nth-of-type(5) {
    width: 14%;
}

.table_row_wrapper p:nth-of-type(6) {
    width: 14%;
}

.table_row_wrapper p:nth-of-type(7) {
    width: 16%;
}

.table_row_wrapper p:nth-of-type(8) {
    width: 10%;
}

.table_row_wrapper p:not(:last-child) {
    border-right: 2px solid #fff;
}

.table_desc_row_wrapper .table_row_wrapper:first-child p:first-child span label {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.table_desc_row_wrapper .table_row_wrapper:last-child p:first-child span label {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.table_desc_row_wrapper .table_row_wrapper p:first-child span label {
    background: #fff;
}

.table_desc_row_wrapper .table_row_wrapper:first-child p:first-child {
    padding: 4px 0.2% 0 0;
    width: 7.8%;
}

.table_desc_row_wrapper .table_row_wrapper:not(:first-child) p:first-child {
    padding: 0 0.2% 0 0;
    width: 7.8%;
}

.table_desc_row_wrapper .table_row_wrapper:not(:last-child) p {
    border-bottom: 2px solid #fff;
}

.table_desc_row_wrapper .table_row_wrapper:not(:last-child) p:first-child {
    border-bottom: 2px solid #c7e1d3;
}

.table_heading_row_wrapper .table_row_wrapper:first-child p:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 3px;
}

.table_heading_row_wrapper .table_row_wrapper:first-child p:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 3px;
}

.table_common_font p {
    color: #000;
    font-weight: 500;
}

.green_bg_color {
    background-color: #aaf55b !important;
}

.orange_bg_color {
    background-color: #ffcf84 !important;
}

.darkr_grey_bgcolor {
    background-color: #83adc8 !important;
}


/* ------------------EXPENSE REDUCTION CSS Ends------------------ */


/* ------------------EXPENSE REDUCTION Responsive Starts------------------ */

@media only screen and (max-width: 1758px) {
    .table_desc_row_wrapper .table_row_wrapper p:first-child span {
        font-size: 18px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 1622px) {
    .table_heading_row_wrapper .table_row_wrapper p {
        font-size: 23px;
        line-height: 28px;
    }
    .table_desc_row_wrapper .table_row_wrapper p {
        font-size: 15px;
    }
    .table_desc_row_wrapper .table_row_wrapper p:first-child span {
        font-size: 16px;
        line-height: 20px;
    }
    .expns_rdctn_wrap>h4 {
        font-size: 32px;
        line-height: 34px;
    }
}

@media only screen and (max-width: 1480px) {
    .table_desc_row_wrapper .table_row_wrapper p {
        font-size: 13px;
    }
    .table_desc_row_wrapper .table_row_wrapper p:first-child span {
        font-size: 14px;
        line-height: 18px;
    }
    .table_heading_row_wrapper .table_row_wrapper p {
        font-size: 21px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 1345px) {
    .table_heading_row_wrapper .table_row_wrapper p {
        font-size: 19px;
        line-height: 24px;
    }
    .table_desc_row_wrapper .table_row_wrapper p:first-child span {
        font-size: 12px;
        line-height: 16px;
    }
    .table_desc_row_wrapper .table_row_wrapper p {
        font-size: 12px;
    }
}

@media only screen and (max-width: 1245px) {
    .table_heading_row_wrapper .table_row_wrapper p {
        font-size: 18px;
        line-height: 23px;
    }
    .table_desc_row_wrapper .table_row_wrapper p:first-child span {
        font-size: 11px;
        line-height: 15px;
    }
    .table_desc_row_wrapper .table_row_wrapper p {
        font-size: 11px;
    }
}

@media only screen and (max-width: 1199px) {
    .table_heading_row_wrapper {
        display: none;
    }
    .table_main_wrapper {
        border: none;
        margin-top: 10px;
    }
    .table_common_font span:before {
        content: attr(data-label);
        background: #013b7f;
        width: 49%;
        padding: 6px;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        display: table-cell;
        vertical-align: middle;
    }
    .table_row_wrapper p span {
        text-align: left;
        display: table-row;
    }
    .table_common_font span label {
        display: table-cell;
        width: 49%;
        padding: 6px!important;
        vertical-align: middle;
    }
    .table_row_wrapper {
        display: block;
        margin-top: 10px;
    }
    .table_row_wrapper:first-of-type {
        margin-top: 0px;
    }
    .table_desc_row_wrapper .table_row_wrapper:not(:first-child) p:first-child {
        padding: 0;
    }
    .table_desc_row_wrapper .table_row_wrapper:not(:last-child) p {
        border-bottom: 1px solid #e1e1e1;
    }
    .table_row_wrapper p {
        width: 100% !important;
        padding: 0;
        display: table;
    }
    .table_desc_row_wrapper .table_row_wrapper:first-child p:first-child span label {
        border-radius: 0;
    }
    .table_desc_row_wrapper .table_row_wrapper:first-child p:first-child {
        padding: 0;
    }
    .table_row_wrapper p:not(:last-child) {
        border: 0
    }
    .table_desc_row_wrapper .table_row_wrapper:not(:last-child) p:first-child {
        border-bottom: 1px solid #b1cbdb;
    }
    .table_desc_row_wrapper .table_row_wrapper:last-child p:first-child {
        border-bottom: 1px solid #e1e1e1;
    }
    .table_desc_row_wrapper .table_row_wrapper:nth-of-type(even) p span label {
        background-color: #b1cbdb;
    }
    .table_desc_row_wrapper .table_row_wrapper:nth-of-type(even) p:first-child span label {
        background-color: #fff;
    }
    .table_desc_row_wrapper .table_row_wrapper:last-child p:first-child span label {
        border-radius: 0;
    }
    .table_desc_row_wrapper .table_row_wrapper:last-child p:not(:first-child) {
        border-bottom: 1px solid #e1e1e1;
    }
    .expns_rdctn_wrap>h2 {
        font-size: 35px;
        text-align: center;
    }
    .expns_rdctn_wrap {
        padding: 12px 8px 12px;
    }
    .color_indicator {
        display: block;
        margin-top: 0;
    }
    .color_indicator p {
        width: auto!important;
        margin-right: 0!important;
        text-align: center;
        padding-left: 0;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .color_indicator p::after {
        content: '';
        position: inherit;
        height: 20px;
        width: 50px;
        transform: inherit;
        top: 0;
        left: 0;
        border-radius: 3px;
        display: inline;
        margin: 0 0 0 20px;
        margin-top: 0;
    }
    .table_desc_row_wrapper .table_row_wrapper p {
        font-size: 16px;
        line-height: 22px;
    }
    .table_desc_row_wrapper .table_row_wrapper p:first-child span {
        font-size: 16px;
        line-height: 22px;
    }
    .table_desc_row_wrapper .table_row_wrapper:nth-of-type(even) p span label {
        background-color: #fff;
    }
    .table_desc_row_wrapper .table_row_wrapper:nth-of-type(even) p:not(:first-child) {
        border-bottom: 1px solid #b1cbdb;
    }
    .expns_rdctn_wrap>h4 {
        font-size: 26px;
        line-height: 28px;
    }
}

@media only screen and (max-width: 792px) {
    .expns_rdctn_wrap>h2 {
        font-size: 32px;
    }
    .expns_rdctn_wrap>h4 {
        font-size: 24px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 736px) {
    .expns_rdctn_wrap>h4 {
        font-size: 27px;
        line-height: 28px;
    }
}

@media only screen and (max-width: 640px) {
    .expns_rdctn_wrap>h2 {
        font-size: 26px;
    }
    .color_indicator p {
        font-size: 16px;
        padding: 5px;
    }
    .expns_rdctn_wrap>h4 {
        font-size: 22px;
        line-height: 24px;
    }
}

@media only screen and (max-width: 520px) {
    .expns_rdctn_wrap>h2 {
        font-size: 22px;
    }
    .color_indicator p {
        font-size: 13px;
        padding: 3px;
    }
    .table_desc_row_wrapper .table_row_wrapper p {
        font-size: 14px;
        line-height: 18px;
    }
    .table_desc_row_wrapper .table_row_wrapper p:first-child span {
        font-size: 14px;
        line-height: 18px;
    }
    .table_common_font label:before {
        font-size: 16px;
    }
    .table_common_font span:before {
        font-size: 16px;
    }
    .expns_rdctn_wrap>h4 {
        font-size: 20px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 440px) {
    .expns_rdctn_wrap>h2 {
        font-size: 19px;
        line-height: 24px;
    }
    .expns_rdctn_wrap>h4 {
        font-size: 18px;
        line-height: 20px;
        padding: 10px 10px;
    }
}

@media only screen and (max-width: 384px) {
    .color_indicator p {
        flex-direction: column;
    }
    .color_indicator p::after {
        margin: 5px 0 0 0;
    }
    .expns_rdctn_wrap>h4 {
        font-size: 14px;
        line-height: 17px;
    }
}


/* ------------------EXPENSE REDUCTION Responsive Ends------------------ */


/* /--------------john dane banner top css start---------/ */

.george_title {
    background-image: url();
}


/* /--------------john dane banner top css end---------/ */


/* /------------- Calculate Popup --------------/ */

.exit-intent-popup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1111;
    /* background: rgba(33, 33, 33, 0.8); */
    transform: translateY(60%) scale(0);
    transition: transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.exit-intent-popup {
    transform: translateY(0) scale(1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.newsletter {
    /* background: linear-gradient(137deg, rgba(249, 246, 203, 1) 0%, rgba(213, 214, 208, 1) 100%); */
    background: #eef2f3;
    border-radius: 3px;
    border: 10px solid #00547c;
    width: 600px;
    margin: 10px auto;
    /* padding: 35px 45px; */
    /* font-family: 'POPPINS'; */
    position: relative;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.6) inset;
}

.exit-intent-popup .close {
    position: absolute;
    top: -22px;
    right: -22px;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background: #fff;
    border-radius: 50%;
    line-height: 34px;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
}

.exit-intent-popup .contentDiv {
    padding: 20px 10px;
}

.exit-intent-popup .contentDiv .contentDiv_heading h2 {
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
}

.exit-intent-popup .contentDiv .contentDiv_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.exit-intent-popup .contentDiv .contentDiv_heading .aspireLogo {
    /* background: linear-gradient(180deg, #adc0ca, #fff 50%, #adc0ca); */
    padding: 20px 5px;
    border-radius: 6px;
    width: 200px;
}

.exit-intent-popup .contentDiv .contentDiv_heading .aspireLogo img {
    width: 200px;
}

.exit-intent-popup .contentDiv .contentDiv_heading img {
    width: 95px;
    margin-right: 5px;
}

.calculateCheckboxOptions {
    width: 86%;
    background: rgba(255, 255, 255, 0.5);
    padding: 10px;
    margin: 10px auto;
    border-radius: 5px;
    box-shadow: 0px 3px 4px rgb(0 0 0 / 10%);
}

.calculateCheckboxOptions h3 {
    font-weight: bold;
}

.calculateCheckboxOptions fieldset {
    margin: 0px;
    width: 100%;
}

.exit-intent-popup .contentDiv .emailInputDiv {
    color: #0062c3;
    width: 90%;
    margin: 10px auto;
    margin-top: 20px;
}

.exit-intent-popup .contentDiv .emailInputDiv input {
    width: 304px;
    line-height: 30px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-indent: 8px;
}

.exit-intent-popup .contentDiv .emailInputDiv #lblError {
    color: red;
    width: 100%;
    display: block;
    text-align: right;
}

.exit-intent-popup .contentDiv button.calculateBtn {
    background: #1976d2;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    padding: 3px 16px;
    border-radius: 4px;
    border: none;
    width: auto;
    color: #fff;
    margin: 15px auto;
    margin-top: 4px;
    display: block;
    font-size: 30px;
    cursor: pointer;
    margin-top: 20px;
}